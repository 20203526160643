'use strict';

module.exports = {
    scrollTo: function ($anchor, offset) {
        offset = offset || 0;
        $([document.documentElement, document.body]).animate(
            {
                scrollTop: $anchor.offset().top - offset,
            },
            500
        );
    },
};
