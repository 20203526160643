/* eslint-disable vars-on-top */
/* eslint-disable indent */
'use strict';

var formFields = require('../components/formFields');
var formValidation = require('../components/formValidation');
var scrollHelper = require('../components/scroll');

module.exports = {
    initialize: function () {
        formFields.initialize();
        if ($('#alert-danger-error').length > 0) {
            scrollHelper.scrollTo($('#alert-danger-error'), 30);
        }
    },
    login: function () {
        $('form.login').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            $('form.login button').addClass('disabled');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                    } else {
                        $('form.login').trigger('login:success', data);
                        location.href = data.redirectUrl;
                    }
                    $('form.login button').removeClass('disabled');
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        form.spinner().stop();
                    }
                    $('form.login button').removeClass('disabled');
                },
            });
            return false;
        });
    },

    register: function () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.registration .btn-primary').attr('disabled', 'disabled');
            $('form.registration').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    $('form.registration .btn-primary').removeAttr('disabled');
                    if (!data.success) {
                        formValidation(form, data);
                        scrollHelper.scrollTo($('.is-invalid').first(), 30);
                    } else {
                        location.href = data.redirectUrl;
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }

                    form.spinner().stop();
                    $('form.registration .btn-primary').removeAttr('disabled');
                },
            });
            return false;
        });
    },

    resetPassword: function () {
        $('.reset-password-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body')
                            .empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');
                        if (!data.mobile) {
                            $('#submitEmailButton').text(data.buttonText).attr('data-dismiss', 'modal');
                        } else {
                            $('.send-email-btn')
                                .empty()
                                .html(
                                    '<a href="' +
                                        data.returnUrl +
                                        '" class="btn btn-primary btn-block">' +
                                        data.buttonText +
                                        '</a>'
                                );
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                },
            });
            return false;
        });
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    },

    focusElementOnShowPanel: function () {
        $('.login-form-nav a.nav-link').on('shown.bs.tab', function (e) {
            var targetLink = $(e.target);
            var targetForm = $(targetLink.attr('href'));
            targetForm.find('input').first().focus();
        });

        $('#login-tab').on('keydown', function (e) {
            if (e.key === 'ArrowRight') {
                $('#register-tab > a').focus();
            }
        });

        $('#register-tab').on('keydown', function (e) {
            if (e.key === 'ArrowLeft') {
                $('#login-tab > a').focus();
            }
        });
    },
};
