'use strict';

module.exports = {
    initialize: function () {
        // Credit card owner field
        $('input#cardOwner').keydown(function (e) {
            // Allow: backspace, delete, tab, escape, enter and .
            if (
                $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                // Allow: Ctrl/cmd+A
                (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+C
                (e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+X
                (e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: +, -, *, (, ), #
                e.keyCode == 187 ||
                e.keyCode == 189 ||
                e.keyCode == 107 ||
                e.keyCode == 109 ||
                e.keyCode == 106 ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)
            ) {
                // let it happen, don't do anything
                return;
            }

            // Ensure that it is not a number and stop the keypress
            if ((!e.shiftKey && e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
                e.preventDefault();
            }
        });

        $('input#giftCardNumber').keydown(function (e) {
            // Allow: backspace, delete, tab, escape and enter
            if (
                $.inArray(e.keyCode, [8, 9, 27, 13, 127]) !== -1 ||
                // Allow: Ctrl/cmd+A
                (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+C
                (e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+X
                (e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)
            ) {
                // let it happen, don't do anything
                return;
            }

            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });
        //Credit card security code field
        $('input#securityCode').keydown(function (e) {
            // Allow: backspace, delete, tab, escape and enter
            if (
                $.inArray(e.keyCode, [8, 9, 27, 13, 127]) !== -1 ||
                // Allow: Ctrl/cmd+A
                (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+C
                (e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+X
                (e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)
            ) {
                // let it happen, don't do anything
                return;
            }

            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });

        // Postal Code field
        $('input[name$=_postalCode], input[name$=Postal]').keydown(function (e) {
            // Allow: backspace, delete, tab, escape, enter, minus, and keypad minus
            if (
                $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 189, 109]) !== -1 ||
                // Allow: Ctrl/cmd+A
                (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+C
                (e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+X
                (e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: +, -, *, (, ), #
                e.keyCode == 187 ||
                e.keyCode == 189 ||
                e.keyCode == 107 ||
                e.keyCode == 109 ||
                e.keyCode == 106 ||
                e.keyCode == 56 ||
                e.keyCode == 57 ||
                e.keyCode == 48 ||
                e.keyCode == 51 ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)
            ) {
                // let it happen, don't do anything
                return;
            }

            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });

        // Phone number field
        $('input[type=tel]').keydown(function (e) {
            // Allow: backspace, delete, tab, escape, enter and .
            if (
                $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                // Allow: Ctrl/cmd+A
                (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+C
                (e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+X
                (e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: +, -, *, (, ), #
                e.keyCode == 187 ||
                e.keyCode == 189 ||
                e.keyCode == 107 ||
                e.keyCode == 109 ||
                e.keyCode == 106 ||
                e.keyCode == 56 ||
                e.keyCode == 57 ||
                e.keyCode == 48 ||
                e.keyCode == 51 ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)
            ) {
                // let it happen, don't do anything
                return;
            }

            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });

        // All input and dropdown fields with invalid values
        $('body').on('blur', 'input.is-invalid, select.is-invalid', function () {
            var $this = $(this),
                pattern = $this.attr('pattern');

            if (pattern) {
                var regex = new RegExp(pattern);
                if (!regex.test($this.val())) {
                    // Input value failed the given regex pattern; do not clear the error message.
                    return;
                }
            }

            if ($this.val().trim() != '') {
                $this.removeClass('is-invalid');
                var $formGroup = $this.parents('.form-group').first();
                $formGroup.find('label.is-invalid').removeClass('is-invalid');
                $formGroup.find('.invalid-feedback').empty();
            }
        });

        // All input and dropdown fields without invalid values
        $('body').on(
            'blur',
            '.form-group.required input:not(.is-invalid), .form-group.required select:not(.is-invalid)',
            function () {
                var $this = $(this);
                var id = $this.attr('id');
                var label = $('label[for=' + id + ']')
                    .text()
                    .trim();
                if (!$this.is(':visible')) {
                    return;
                }
                if ($this.val().trim() === '') {
                    $this.addClass('is-invalid');
                    var $formGroup = $this.parents('.form-group').first();
                    $formGroup.find('label.form-control-label').addClass('is-invalid');
                    $formGroup.find('.invalid-feedback').text(label + ' field is required.');
                    $this.focus();
                }
            }
        );
    },
};
