'use strict';

const daysInMonth = {
    1: 31,
    2: 29,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
};

module.exports = function () {
    var removedDays = [];

    $(document).on('change', '#birthdayMonth', function (e) {
        if (removedDays.length > 0) {
            removedDays.forEach((day) => {
                $('#birthdayDay').append($(day));
            });
            removedDays = [];
        }
        var selectedMonth = e.target.value;
        var selectedDay = $('#birthdayDay').val();

        // If the current value is about to be removed, then clear it
        if (selectedDay > daysInMonth[selectedMonth]) $('#birthdayDay').val('');

        // Remove the days that aren't in the current month
        $('#birthdayDay option').each(function () {
            var $this = $(this);
            if ($this.val() > daysInMonth[selectedMonth]) {
                removedDays.push($this.detach());
            }
        });
    });
};
